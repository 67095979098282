import React, { useEffect, useState } from "react";
import AppLayout from "./Components/Layout/AppLayout";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./Routes/PublicRoutes";
import updateUserStates, {
  handleLogout,
  handleUpdate,
} from "./Utils/UpdateUsersState";
import { userDetails } from "./API/userDetails";
import axios from "axios";
import { decryptText, encryptText } from "./Utils/Encryption";
import { refresh } from "./API/auth";
import PaymentWarningModal from "./Components/Common/paymentErrorModal";

const App = () => {
  const [userData, setUserData] = useState({
    username: "",
    role: "",
    id: 0,
    account: false,
    paid: false,
    payment_status: false,
  });
  const [userToken, setUserToken] = useState({
    access: "",
    refresh: "",
  });
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [search, setSearch] = useState("");
  const [notificationType, setNotificationType] = useState(null);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        const localStorageItems = localStorage;

        if (localStorageItems.length !== 0) {
          for (let key in localStorageItems) {
            if (localStorageItems[key] && decryptText(key) === "user") {
              localStorage.removeItem(key);
            }

            if (localStorageItems[key] && decryptText(key) === "token") {
              localStorage.removeItem(key);
            }
          }
        }
        window.location.href = "/login";
      }
    }
  );

  const refreshCall = async (userData, userToken) => {
    if (userToken?.access && userToken?.refresh && userData?.username) {
      const data = await refresh(userToken);
      if (data?.status === 200) {
        const localStorageItems = localStorage;
        if (localStorageItems.length !== 0) {
          for (let key in localStorageItems) {
            if (localStorageItems[key] && decryptText(key) === "token") {
              localStorage.removeItem(key);
            }
          }
        }
        localStorage.setItem(
          encryptText("token"),
          encryptText(
            JSON.stringify({
              access: data?.data?.access,
              refresh: data?.data?.refresh,
            })
          )
        );
        setUserToken({
          access: data?.data?.access,
          refresh: data?.data?.refresh,
        });
        if (
          userData?.paid !== data?.data?.paid ||
          userData?.payment_status !== data?.data?.payment_status
        ) {
          handleUpdate(
            false,
            userData,
            setUserData,
            data?.data?.paid,
            data?.data?.payment_status
          );
        }
      } else {
        handleLogout(setUserData, setUserToken);
        setUser({});
      }
    }
  };

  useEffect(() => {
    let updatedStates;
    const func = async () => {
      setLoading(true);
      if (userData?.role === "" || userToken?.access === "") {
        updatedStates = updateUserStates(setUserData, setUserToken);
      }
    };
    func();
    if (
      updatedStates?.token?.access &&
      updatedStates?.token?.refresh &&
      updatedStates?.user?.username &&
      loading
    ) {
      refreshCall(updatedStates?.user, updatedStates?.token);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      refreshCall(userData, userToken);
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [userToken, userData, window.location.pathname]);

  useEffect(() => {
    const func = async () => {
      if (userData?.id) {
        const res = await userDetails();
        setUser(res?.data);
      }
    };
    func();
    userData?.payment_status === false &&
      userData?.paid === true &&
      userData?.username &&
      setOpenWarningModal(true);
  }, [userData]);

  return (
    <div className="App">
      {loading ? null : (
        <BrowserRouter>
          {userData?.username ? (
            <AppLayout
              setUserData={setUserData}
              setUserToken={setUserToken}
              userData={userData}
            />
          ) : (
            <PublicRoutes
              setUserData={setUserData}
              userToken={userToken}
              setUserToken={setUserToken}
              userData={userData}
              user={user}
              setUser={setUser}
              notificationType={notificationType}
              setNotificationType={setNotificationType}
            />
          )}
        </BrowserRouter>
      )}
      {openWarningModal && (
        <PaymentWarningModal
          open={
            !userData?.payment_status &&
            userData?.username.length &&
            userData?.paid
          }
          setUserData={setUserData}
          setUserToken={setUserToken}
        />
      )}
    </div>
  );
};

export default App;
