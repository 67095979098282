import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../Pages/Login";
import Signup from "../Pages/signup";

const PublicRoutes = ({
  setUserData,
  setUserToken,
  notificationType,
  setNotificationType,
}) => {
  return (
    <Routes>
      <Route
        path="/signup"
        element={<Signup setNotificationType={setNotificationType} />}
      />
      <Route
        path="/login"
        element={
          <Login
            setUserData={setUserData}
            setUserToken={setUserToken}
            notificationType={notificationType}
            setNotificationType={setNotificationType}
          />
        }
      />
      <Route path="*" element={<Navigate to={"/login"} />} />
    </Routes>
  );
};

export default PublicRoutes;
