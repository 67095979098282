import React from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import AppRoutes from "../../Routes/AppRoutes";
import "../../Assets/Styles/layout.css";
import SubscriptionRoutes from "../../Routes/SubscriptionRoutes";

const { Content } = Layout;

const AppLayout = ({ setUserData, userData, setUserToken }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {userData?.paid && <Sidebar />}
      <Layout
        className="site-layout"
        style={{ marginLeft: userData?.paid ? "80px" : "0px" }}
      >
        <Navbar
          setUserData={setUserData}
          setUserToken={setUserToken}
          userData={userData}
        />
        <Content className="content">
          {userData?.paid ? (
            <AppRoutes userData={userData} setUserData={setUserData} />
          ) : (
            <SubscriptionRoutes userData={userData} setUserData={setUserData} />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
