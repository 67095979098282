import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Domains from "../Pages/Domains";
import Dashboard from "../Pages/Dashboard";
import Keywords from "../Pages/Keywords";
import Subscription from "../Pages/Subscription";
import Profile from "../Pages/Profile";

const AppRoutes = ({ userData, setUserData }) => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/domains" element={<Domains />} />
      <Route path="/domains/:domainId" element={<Keywords />} />
      <Route
        path="/payment"
        element={<Subscription userData={userData} setUserData={setUserData} />}
      />
      <Route path="/profile" element={<Profile />} />
      <Route path="*" element={<Navigate to={"/dashboard"} />} />
    </Routes>
  );
};

export default AppRoutes;
