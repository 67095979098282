import axios from "axios";

const login = async (email, password) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/token/`,
      {
        email,
        password,
      }
    );

    return res;
  } catch (e) {
    console.log(e);
  }
};

const signup = async (email, password, name) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/register/`,
      {
        email,
        password,
        name,
      }
    );

    return res;
  } catch (e) {
    console.log(e);
  }
};

const refresh = async (token, id) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/token/refresh/`,
      { refresh: token?.refresh },
      {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const checkOTP = async (token, otp) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/check_otp/${otp}`,
      {
        Authorization: `Bearer ${token?.access}`,
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export { login, signup, refresh, checkOTP };
