import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const getAllKeywords = async (domainId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/keyword/?domainId=${domainId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.error("Error fetching keywords:", error);
    throw error;
  }
};

const createKeyword = async (domainId, keywords, city, flag_city) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/keyword/`,
      {
        domain: domainId,
        keywords,
        city,
        flag_city,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.error(e, "Error");
    return e;
  }
};

const deleteKeyword = async (keywordId) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/keyword/?keywordId=${keywordId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e, "Error");
    throw e;
  }
};

export { createKeyword, getAllKeywords, deleteKeyword };
