import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const createDomain = async (domainName, url, location, city, flag_city) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/domain/`,
      {
        domainName,
        url,
        location,
        city,
        flag_city,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e, "error");
    return e;
  }
};

const deleteDomain = async (domainId) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/domain/${domainId}/`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.error("Error deleting domain:", e);
    throw e;
  }
};

const getAllDomains = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/domain`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.error("Error fetching all domains:", e);
    throw e;
  }
};

const getDomain = async (domainId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/domain/${domainId}/`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.error("Error fetching all domains:", e);
    throw e;
  }
};

const editDomain = async (
  domainId,
  domainName,
  url,
  location,
  city,
  flag_city
) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/api/domain/${domainId}/`,
      {
        domainName,
        url,
        location,
        city,
        flag_city,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.error("Error editing domain:", e);
    throw e;
  }
};

export { createDomain, editDomain, deleteDomain, getAllDomains, getDomain };
