import { Button, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Assets/Styles/plans.css";
import { getPlans } from "../../API/plans";

const Plans = () => {
  const [plans, setPlans] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTime, setSelectedTime] = useState("month");
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getPlans();
      const plansObject = { year: [], month: [] };
      for (let x in res?.data) {
        plansObject[res?.data[x]?.interval].push(res?.data[x]);
      }
      plansObject.month.sort((a, b) => {
        return a.price - b.price;
      });
      plansObject.year.sort((a, b) => {
        return a.price - b.price;
      });
      setPlans(plansObject);
      setLoading(false);
    };
    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleChoosePlan = (e) => {
    navigate("/checkout-form", {
      state: e,
    });
  };

  const onChange = (key) => {
    setSelectedTime(key);
  };

  const items = [
    {
      key: "month",
      label: "Monthly",
    },
    {
      key: "year",
      label: "Yearly",
    },
  ];

  return (
    <div className="plans">
      {!loading ? (
        <div className="container">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            style={{ color: "pink" }}
          />
          <div className="plan-cards-container">
            {plans?.[selectedTime]?.map((plan) => (
              <div className="plan-card" key={plan?.price_id}>
                <h1>{plan?.name}</h1>
                <div className="plan-price">
                  <span>{plan?.currency}</span>
                  <span>{plan?.price}</span>
                  <span> / </span>
                  <span>{plan?.interval}</span>
                </div>
                <span className="desc">{plan?.description}</span>
                <Button
                  onClick={() => {
                    handleChoosePlan(plan);
                  }}
                >
                  Choose Plan
                </Button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Spin style={{ marginTop: "20vh" }} size={"large"} />
        </div>
      )}
    </div>
  );
};

export default Plans;
