import {
  DeploymentUnitOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "../Assets/Styles/pages.css";
import { Button, Popconfirm, Space, Table, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useNavigate } from "react-router-dom";
import { deleteDomain, getAllDomains } from "../API/domainApis";
import ModalDomain from "../Components/Common/modalDomain";

const Domains = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [editId, setEditId] = useState(null);
  const [size] = useState("large");
  const [domainData, setDomainData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getAllDomains();
      if (Math.floor(res.status / 100) === 2) {
        setDomainData(res.data);
      } else {
        console.error("Failed to fetch domain data:", res.data);
      }
    } catch (error) {
      console.error("An error occurred while fetching domain data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAddDomain = () => {
    setIsAdding(true);
    showModal();
  };

  const handleEdit = async (id) => {
    setIsEditing(true);
    setEditId(id);
    showModal();
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteDomain(id);
      if (Math.floor(res.status / 100) === 2) {
        const updatedDomainData = domainData.filter(
          (domain) => domain.id !== id
        );
        setDomainData(updatedDomainData);
      } else {
        console.error("Failed to delete domain:", res.data);
      }
    } catch (error) {
      console.error("An error occurred while deleting domain:", error);
    }
    setIsLoading(false);
  };

  const handleRowClick = (record) => {
    navigate(`/domains/${record.id}`);
  };

  return (
    <div className="tab-view">
      <Tabs defaultActiveKey="1" type="card" size={size}>
        <TabPane tab="Domains" key="1" icon={<DeploymentUnitOutlined />}>
          <div className="content-dashboard">
            <div className="add-domain-wrapper">
              <Button
                style={{ backgroundColor: "#FBB200", color: "white" }}
                icon={<PlusCircleOutlined />}
                onClick={handleAddDomain}
              >
                Add Domain
              </Button>
            </div>
            <Table
              loading={loading}
              dataSource={domainData}
              pagination={false}
              scroll={{ x: "768" }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
              style={{ cursor: "pointer" }}
            >
              <Table.Column
                title="Domain Name"
                dataIndex="domainName"
                key="domainName"
                render={(value, record) => {
                  return (
                    <div className="domain-styling">
                      <span className="domain-name">{value}</span>
                      <span className="domain-url">{record.url}</span>
                    </div>
                  );
                }}
              />
              <Table.Column
                title="Number of Keywords"
                dataIndex="numKeywords"
                key="numKeywords"
              />
              <Table.Column
                title="Created On"
                dataIndex="date_created"
                key="date"
              />
              <Table.Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <Space>
                    <Button
                      icon={<EditOutlined style={{ color: "green" }} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(record.id);
                      }}
                    />
                    <Popconfirm
                      title="Are you sure to delete this domain?"
                      onConfirm={(e) => {
                        e.stopPropagation();
                        handleDelete(record.id);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        loading={isLoading}
                        icon={<DeleteOutlined style={{ color: "red" }} />}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </Popconfirm>
                  </Space>
                )}
              />
            </Table>
          </div>
        </TabPane>
      </Tabs>

      <ModalDomain
        isAdding={isAdding}
        setIsAdding={setIsAdding}
        isEditing={isEditing}
        isModalVisible={isModalVisible}
        setIsEditing={setIsEditing}
        setIsModalVisible={setIsModalVisible}
        setData={setDomainData}
        editId={editId}
      />
    </div>
  );
};

export default Domains;
