import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  DesktopOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import "../../Assets/Styles/layout.css";

const { Sider } = Layout;

const Sidebar = () => {
  const path = useLocation();
  const locationPath = path.pathname;

  const [selectedId, setSelectedId] = useState("1");

  useEffect(() => {
    if (locationPath.includes("/dashboard")) {
      setSelectedId("1");
    } else if (locationPath.includes("/payment")) {
      setSelectedId("3");
    } else if (locationPath.includes("/profile")) {
      setSelectedId("4");
    } else {
      setSelectedId("2");
    }
  }, [locationPath]);

  return (
    <Sider
      width={80}
      theme="dark"
      breakpoint="md"
      collapsedWidth="0"
      style={{ height: "100vh", position: "fixed" }}
    >
      <div
        style={{
          marginTop: "10px",
          marginBottom: "15px",
          display: "grid",
          placeItems: "center",
        }}
      >
        <img width={50} style={{ padding: "5px" }} src={logo} alt="test-logo" />
      </div>
      <Menu
        mode="vertical"
        theme="dark"
        defaultSelectedKeys={["1"]}
        selectedKeys={[selectedId]}
      >
        <Menu.Item key="1" style={{ fontSize: "10px", height: "fitContent" }}>
          <Link to="/dashboard">
            <DesktopOutlined style={{ fontSize: "30px" }} />
            <span className="menu-item-label">Dashboard</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2" style={{ fontSize: "10px" }}>
          <Link to="/domains">
            <DeploymentUnitOutlined style={{ fontSize: "30px" }} />
            <span className="menu-item-label">Domains</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3" style={{ fontSize: "10px" }}>
          <Link to="/payment">
            <DollarCircleOutlined style={{ fontSize: "30px" }} />
            <span className="menu-item-label">Payment</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="4" style={{ fontSize: "10px" }}>
          <Link to="/profile">
            <UserOutlined style={{ fontSize: "30px" }} />
            <span className="menu-item-label">Profile</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
