import { Button, Popconfirm, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "../../Assets/Styles/plans.css";
import { cancelPlan, changePlan, getPlans } from "../../API/plans";
import { handleUpdate } from "../../Utils/UpdateUsersState";
import { CheckCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

const Plans = ({ userData, setUserData }) => {
  const [plans, setPlans] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTime, setSelectedTime] = useState("month");

  const getAllPlans = async () => {
    const plans = (await getPlans())?.data;
    const plansObject = { year: [], month: [] };
    for (let x in plans) {
      plansObject[plans[x]?.interval]?.push(plans[x]);
      if (plans[x]?.selected) {
        setSelectedTime(plans[x]?.interval);
      }
    }
    plansObject.month.sort((a, b) => {
      return a.price - b.price;
    });
    plansObject.year.sort((a, b) => {
      return a.price - b.price;
    });
    setPlans(plansObject);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getAllPlans();
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleChangePlan = async (e) => {
    setLoading(true);

    try {
      const res = await changePlan(e.price_id);
      if (!res) {
        notification.error({
          message: "Keyword Limit Exceeds",
          description: "Delete Access Keywords to change plan",
        });
      }
    } catch (e) {
      console.log("error iugasi");
      notification.error({
        message: "Keyword Limit Exceeds",
        description: "Delete Access Keywords to change plan",
      });
    }

    await getAllPlans();
    setLoading(false);
  };

  const handleCancelPlan = async () => {
    setLoading(true);
    await cancelPlan();
    getAllPlans();
    handleUpdate(false, userData, setUserData, false, false);
    setLoading(false);
  };

  const onChange = (key) => {
    setSelectedTime(key);
  };

  const items = [
    {
      key: "month",
      label: "Monthly",
    },
    {
      key: "year",
      label: "Yearly",
    },
  ];

  return (
    <div className="plans">
      {!loading ? (
        <div className="container">
          <Tabs
            defaultActiveKey={selectedTime}
            items={items}
            onChange={onChange}
            style={{ color: "pink" }}
          />
          <div className="plan-cards-container">
            {plans?.[selectedTime]?.map((plan) => (
              <div className="plan-card" key={plan?.price_id}>
                <div className="selected-plan-icon">
                  {plan.selected && (
                    <CheckCircleOutlined
                      style={{ fontSize: "24px", color: "green" }}
                    />
                  )}
                </div>
                <h1>{plan?.name}</h1>
                <div className="plan-price">
                  <span>{plan?.currency}</span>
                  <span>{plan?.price}</span>
                  <span> / </span>
                  <span>{plan?.interval}</span>
                </div>
                <span className="desc">{plan?.description}</span>
                {!plan.selected ? (
                  <Popconfirm
                    title="Are you sure you want to choose this plan?"
                    onConfirm={() => handleChangePlan(plan)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button>Choose Plan</Button>
                  </Popconfirm>
                ) : (
                  <>
                    <Popconfirm
                      title="Are you sure you want to cancel your subscription? NOTE: ALL YOUR KEYWORDS WILL BE DELETED"
                      onConfirm={handleCancelPlan}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className="cancel-btn" style={{ color: "red" }}>
                        Cancel Subscription
                      </Button>
                    </Popconfirm>

                    <span className="end-date" style={{ fontWeight: "600" }}>
                      Current Validity: {plan?.next_date}
                    </span>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Spin style={{ marginTop: "20vh" }} size={"large"} />
        </div>
      )}
    </div>
  );
};

export default Plans;
