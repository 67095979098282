import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const getRankings = async (domainId, selectedDate) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/ranking/?domainId=${domainId}&date=${selectedDate}`,

      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e, "Error");
    return e;
  }
};

export { getRankings };
