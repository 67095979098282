import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Plans from "../Pages/Payment/Plans";
import Payment from "../Pages/Payment/Payment";
import Success from "../Pages/Payment/Success";

const SubscriptionRoutes = ({ setUserData, userData }) => {
  return (
    <Routes>
      <Route path="/plans" element={<Plans />} />
      <Route path="/checkout-form" element={<Payment />} />
      <Route
        path="/success/:checkout_id"
        element={<Success setUserData={setUserData} userData={userData} />}
      />
      <Route path="*" element={<Navigate to={"/plans"} />} />
    </Routes>
  );
};

export default SubscriptionRoutes;
