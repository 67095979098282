import React from "react";
import { Layout, Menu, Dropdown, Button, Avatar } from "antd";
import { LogoutOutlined, CaretDownOutlined } from "@ant-design/icons";
import defaultPicture from "../../Assets/Images/default picture.png";
import "../../Assets/Styles/layout.css";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../Utils/UpdateUsersState";

const { Header } = Layout;

const Navbar = ({ setUserData, setUserToken }) => {
  const navigate = useNavigate();

  const handleLogoutPage = () => {
    navigate("/login");
    handleLogout(setUserData, setUserToken);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="logout"
        onClick={handleLogoutPage}
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Header className="navbar" style={{ background: "#001628", padding: 0 }}>
      <div style={{ float: "right", marginRight: "16px" }}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="text" style={{ padding: 0 }}>
            <Avatar src={defaultPicture} alt="Profile" size={40} />
            <CaretDownOutlined
              style={{ marginLeft: "8px", color: "#D3D3D3", fontSize: "10px" }}
            />
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

export default Navbar;
