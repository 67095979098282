import { Spin } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkPaymentStatus } from "../../API/payment";
import "../../Assets/Styles/plans.css";
import { handleUpdate } from "../../Utils/UpdateUsersState";

const Success = ({ userData, setUserData }) => {
  const { checkout_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await checkPaymentStatus(checkout_id);
      if (res?.status && res?.status === 200) {
        handleUpdate(false, userData, setUserData, true, true);
        navigate("/dashboard");
      } else {
        navigate("/plans");
      }
    };
    fetchData();
  });

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          margin: "auto auto",
          width: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spin size="large" />
        <h1>Payment Check In Progress</h1>
        <p>Please wait, you will be redirected to your homepage shortly</p>
      </div>
    </div>
  );
};

export default Success;
