import { decryptText, encryptText } from "./Encryption";

const updateUserStates = (setUserData, setUserToken) => {
  const localStorageItems = localStorage;
  const obj = {};

  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "user") {
        obj.user =
          localStorageItems[key] &&
          JSON.parse(decryptText(localStorageItems[key]));
      }

      if (localStorageItems[key] && decryptText(key) === "token") {
        obj.token =
          localStorageItems[key] &&
          JSON.parse(decryptText(localStorageItems[key]));
      }
    }
  }
  setUserData(obj.user);
  setUserToken(obj.token);
  return obj;
};

const getToken = () => {
  const localStorageItems = localStorage;

  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "token") {
        return JSON.parse(decryptText(localStorageItems[key])).access;
      }
    }
  }
};

const getUser = async () => {
  const localStorageItems = localStorage;

  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "user") {
        return JSON.parse(decryptText(localStorageItems[key]));
      }
    }
  }
};

const handleLogout = (setUserData, setUserToken) => {
  const localStorageItems = localStorage;

  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "user") {
        localStorage.removeItem(key);
      }

      if (localStorageItems[key] && decryptText(key) === "token") {
        localStorage.removeItem(key);
      }
    }
    setUserData({ username: "", role: "", account: false });
    setUserToken({ access: "", refresh: "" });
  }
};

const handleUpdate = (
  account,
  userData,
  setUserData,
  paid = null,
  payment_status = null
) => {
  const localStorageItems = localStorage;
  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "user") {
        localStorage.removeItem(key);
      }
    }
    localStorage.setItem(
      encryptText("user"),
      encryptText(
        JSON.stringify({
          username: userData?.username,
          role: userData?.role,
          account,
          id: userData?.id,
          paid: paid === null ? userData?.paid : paid,
          payment_status:
            payment_status === null ? userData?.payment_status : payment_status,
        })
      )
    );
    setUserData({
      ...userData,
      account,
      paid: paid === null ? userData?.paid : paid,
      payment_status:
        payment_status === null ? userData?.payment_status : payment_status,
    });
  }
};

export { handleLogout, handleUpdate, getToken, getUser };
export default updateUserStates;
