import { Button, Checkbox, Form, Input, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { createDomain, editDomain, getDomain } from "../../API/domainApis";
import { getCity, getLocation } from "../../API/getLocation";

const ModalDomain = ({
  isAdding,
  setIsAdding,
  isEditing,
  isModalVisible,
  setIsModalVisible,
  setIsEditing,
  setData,
  editId,
}) => {
  const formRef = React.useRef();
  const [editingDomain, setEditingDomain] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionsCity, setOptionsCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cityCheck, setCityCheck] = useState(false);

  useEffect(() => {
    const getOptions = async () => {
      const locations = await getLocation();
      setOptions(locations);
    };
    getOptions();
  }, [isAdding]);

  const onCoutryChange = async (countryCode) => {
    const cities = await getCity(countryCode);
    if (cities.status === 200) {
      setOptionsCity(cities?.data);
    } else {
      setOptionsCity([]);
    }
  };

  useEffect(() => {
    const func = async () => {
      setEditingDomain(null);
      try {
        const res = await getDomain(100);
      } catch (e) {
        console.log("error");
      }
      setEditingDomain("error");
    };

    const fetchEditData = async () => {
      try {
        const res = await getDomain(editId);
        if (Math.floor(res.status / 100) === 2) {
          onCoutryChange(res.data.location);
          setEditingDomain(res.data);
          setCityCheck(res?.data?.flag_city);
        } else {
          console.error("Failed to fetch dashboard data:", res.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching dashboard data:",
          error
        );
      }
    };
    if (isEditing) {
      fetchEditData();
    }
    if (isAdding) {
      setIsAdding(false);
      func();
    }
  }, [isEditing, editId, isAdding]);

  const handleCancel = () => {
    setEditingDomain(null);
    setIsAdding(false);
    formRef.current.resetFields();
    setIsModalVisible(false);
  };

  const handleCancelEdit = () => {
    setEditingDomain(null);
    setIsEditing(false);
    formRef.current.resetFields();
    handleCancel();
  };

  const handleUpdate = async (values) => {
    if (!cityCheck) {
      values.city = options.find(
        (item) => item.value === values.location
      ).label;
    }
    setLoading(true);
    try {
      const res = await editDomain(
        editId,
        values.domainName,
        values.url,
        values.location,
        values.city,
        cityCheck
      );

      if (Math.floor(res.status / 100) === 2) {
        setData(res.data);
        setEditingDomain(null);
        setIsEditing(false);
        formRef.current.resetFields();
        setIsModalVisible(false);
      } else {
        console.error("Failed to edit domain:", res.data);
      }
    } catch (error) {
      console.error("An error occurred while editing domain:", error);
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    if (!cityCheck) {
      values.city = options.find(
        (item) => item.value === values.location
      ).label;
    }
    setLoading(true);
    try {
      const res = await createDomain(
        values.domainName,
        values.url,
        values.location,
        values.city,
        cityCheck
      );

      if (Math.floor(res.status / 100) === 2) {
        setData((prev) => [...prev, res.data]);
        setIsAdding(false);
        setEditingDomain(null);
        formRef.current.resetFields();
        setIsModalVisible(false);
      } else {
        console.error("Failed to create domain:", res.data);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setLoading(false);
  };

  return (
    <Modal
      title={isEditing ? "Edit Domain" : "Add Domain"}
      visible={isModalVisible}
      onCancel={isEditing ? handleCancelEdit : handleCancel}
      footer={null}
    >
      {!editingDomain && !isAdding ? (
        <Spin tip="Loading...">
          <div style={{ height: 50 }} />
        </Spin>
      ) : (
        <Form
          onFinish={isEditing ? handleUpdate : onFinish}
          ref={formRef}
          layout="vertical"
          initialValues={editingDomain}
        >
          <Form.Item
            label="Domain Name"
            name="domainName"
            rules={[
              { required: true, message: "Please input the domain name!" },
            ]}
          >
            <Input placeholder="Domain" />
          </Form.Item>
          <Form.Item
            label="URL"
            name="url"
            rules={[{ required: true, message: "Please input the URL!" }]}
          >
            <Input placeholder="URL" />
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            rules={[{ required: true, message: "Please select Country" }]}
          >
            <Select
              options={options}
              onChange={onCoutryChange}
              showSearch
              optionFilterProp="label"
              placeholder="Select Country"
            />
          </Form.Item>

          <Checkbox
            checked={cityCheck}
            onChange={() => setCityCheck(!cityCheck)}
            style={{ width: "fit-content" }}
          >
            Select City
          </Checkbox>

          {cityCheck && (
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "Please select City" }]}
            >
              <Select
                options={optionsCity}
                showSearch
                placeholder="Select City"
                optionFilterProp="value"
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#FBB200",
                color: "white",
              }}
            >
              {isEditing ? "Update Domain" : "Add Domain"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default ModalDomain;
