import React, { useEffect, useMemo, useState } from "react";
import "../Assets/Styles/pages.css";
import { Button, Form, Input, Tabs, notification } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { updateUser } from "../API/userDetails";
import { getUser, handleUpdate } from "../Utils/UpdateUsersState";

const Profile = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (data, type) => {
    api[type]({
      message: data,
    });
  };

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await getUser();
      setUser(userData);
    };

    fetchUserData();
  }, []);

  const handleNameUpdate = async () => {
    try {
      setLoading(true);
      const res = await updateUser({
        name: user.name,
      });

      if (!res) {
        openNotification("Name Updation Unsuccessful", "error");
      } else {
        openNotification("Name Updated Successfully", "success");
        handleUpdate(user?.account, user);
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
    setLoading(false);
  };

  const handleChangePassword = async (values) => {
    try {
      setLoading(true);
      const res = await updateUser({
        password: values.newPassword,
        old: values.oldPassword,
      });

      if (!res) {
        openNotification("Old Password Incorrect", "error");
      } else {
        openNotification("Password Successfully Changed", "success");
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
    setLoading(false);
  };

  return (
    <div className="tab-view">
      {contextHolder}
      <Tabs defaultActiveKey="1" type="card" size="large">
        <TabPane tab="Profile Information" key="1" icon={<UserOutlined />}>
          <div className="content-dashboard" style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                Name:
              </span>
              <Input
                type="text"
                value={user?.name}
                onChange={(e) =>
                  setUser((prevUser) => ({ ...prevUser, name: e.target.value }))
                }
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "6px",
                  flex: "1",
                }}
              />
              <Button
                style={{
                  marginLeft: "20px",
                  backgroundColor: "#011628",
                  color: "white",
                }}
                loading={loading}
                onClick={handleNameUpdate}
              >
                Update
              </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                Email:
              </span>
              <span>{user?.username}</span>
            </div>
          </div>
        </TabPane>

        <TabPane tab="Change Password" key="2" icon={<LockOutlined />}>
          <div className="content-dashboard">
            <Form
              style={{ margin: "0px" }}
              name="changePasswordForm"
              onFinish={handleChangePassword}
              layout="vertical"
            >
              <Form.Item
                label="Old Password"
                name="oldPassword"
                rules={[
                  { required: true, message: "Please enter your old password" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  { required: true, message: "Please enter a new password" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Confirm New Password"
                name="confirmNewPassword"
                dependencies={["newPassword"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords do not match")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  style={{ backgroundColor: "#011628", color: "white" }}
                  type="primary"
                  htmlType="submit"
                >
                  Change Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Profile;
