import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const getLocation = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/country/`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res?.data;
  } catch (e) {
    console.log(e, "error");
    return e;
  }
};

const getCity = async (countryCode) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/city/?country=${countryCode}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e, "error");
    return e;
  }
};

export { getLocation, getCity };
