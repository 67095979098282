import React, { useEffect, useState } from "react";
import "../Assets/Styles/login.css";
import { Button, Col, Form, Input, Row, notification } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../Assets/Images/logo.png";
import { login } from "../API/auth";
import { encryptText } from "../Utils/Encryption";
import updateUserStates, { handleLogout } from "../Utils/UpdateUsersState";

const Login = ({
  setUserData,
  setUserToken,
  notificationType,
  setNotificationType,
}) => {
  const [error, setError] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (notificationType === "success") {
      api["success"]({
        message: "Account Created Successfully",
        description: "Kindly Signin to your account!",
      });
      setNotificationType(null);
    } else if (notificationType === "error") {
      api["error"]({
        message: "Account Was Not Created",
        description: "Please try again!",
      });
      setNotificationType(null);
    }
  }, [notificationType]);

  const onFinish = async (values) => {
    setLoading(true);
    const loginData = await login(values?.email, values?.password);

    if (loginData?.status === 200) {
      localStorage.setItem(
        encryptText("token"),
        encryptText(
          JSON.stringify({
            access: loginData?.data?.access,
            refresh: loginData?.data?.refresh,
          })
        )
      );
      localStorage.setItem(
        encryptText("user"),
        encryptText(
          JSON.stringify({
            id: loginData?.data?.id,
            username: loginData?.data?.username,
            role: loginData?.data?.role,
            name: loginData?.data?.name,
            account: false,
            paid: loginData?.data?.paid,
            payment_status: loginData?.data?.payment_status,
          })
        )
      );

      updateUserStates(setUserData, setUserToken);
      navigate(loginData?.data?.paid ? "/dashboard" : "/plans");
    } else setError("*wrong email/password");
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <div className="box-container">
      {contextHolder}
      <Row className="nav">
        <Col span={5} md={7} sm={10} xs={20} className="data">
          <img style={{ width: "60px" }} src={logo} alt="logo" />
        </Col>
      </Row>
      <div className="login-box">
        <div>
          <span className="title">Log In</span>
        </div>
        <div>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 400 }}
            initialValues={{ remember: false }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="form"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "12px 0px",
              }}
            >
              <NavLink to="/signup">Create Account</NavLink>
            </div>

            {error ? <span style={{ color: "red" }}>{error}</span> : <></>}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
