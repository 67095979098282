import { Button, Modal } from "antd";
import React from "react";
import "./index.css";
import warning from "../../../Assets/Images/warning.png";
import { handleLogout } from "../../../Utils/UpdateUsersState";

const PaymentWarningModal = ({ open, setUserData, setUserToken }) => {
  return (
    <div>
      <Modal
        open={open}
        title={"Access Denied"}
        footer={null}
        className="payment-warning-modal"
        closeIcon={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "25px",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <img src={warning} alt="warning" width={"100px"} />
          <h1 style={{ margin: "10px" }}>Payment Failed</h1>
          <p style={{ margin: "0px" }}>
            Please Pay Your Subscription to Continue!
          </p>
          <Button onClick={() => handleLogout(setUserData, setUserToken)}>
            Logout
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentWarningModal;
